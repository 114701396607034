











































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    type: String,
    large: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
